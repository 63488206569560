import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
import styles from './index.module.css'
import { Grid, Typography, Button, Divider } from '@material-ui/core'
import ShareSocial from '../../components/share-social'

class Articolo extends React.Component {
  render() {
    const articolo = get(this.props, 'data.contentfulArticolo')
    const categorie = get(this.props, 'data.contentfulArticolo.categorie')
    const siteTitle = `Movimento Rivoluzione Fiscale - ${get(
      this,
      'props.data.site.siteMetadata.title'
    )}`

    return (
      <Layout location={this.props.location}>
        <Helmet title={`${articolo.titolo} | ${siteTitle}`}>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <meta property="og:url" content={`https://www.movimentorivoluzionefiscale.it/articoli/${articolo.slug}/`} />
          <meta property="og:title" content={siteTitle} />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={articolo.contenuto.contenuto.substring(0,200)} />
          <meta property="og:image" content={articolo.heroImage.file.url} />
        </Helmet>
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles.titleContainer}>
                {articolo.categorie.map((categoria) => (
                  <Typography variant={'h3'} className={styles.labelCategorie}>
                    {categoria.label}
                  </Typography>
                ))}
              </Grid>
              <Grid item lg={12}>
                <div className={styles.hero}>
                  <Img
                    className={styles.heroImage}
                    alt={articolo.titolo}
                    fluid={articolo.heroImage.fluid}
                  />
                </div>
                <div className={styles.content}>
                  <Typography variant={'h3'}>{articolo.titolo}</Typography>
                  <Typography variant={'subtitle1'} className={styles.author}>
                    {`${articolo.autore.nome} ${articolo.autore.cognome}, ${articolo.dataPubblicazione}`}
                  </Typography>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: articolo.contenuto.childMarkdownRemark.html,
                    }}
                  />
                  <Divider style={{marginBottom: 30}} />
                  <ShareSocial
                    link={this.props.location.href}
                    title={siteTitle}
                  />
                  <Grid
                    container
                    className={styles.buttonsContainer}
                    spacing={2}
                  >
                    {articolo.categorie.map((categoria) => (
                      <Grid item xs={6} md={4} lg={3}>
                        <Button
                          className={styles.buttonCategoria}
                          onClick={() => {
                            window.location.href = `/${categoria.slug}/1/`
                          }}
                        >
                          {categoria.label}: Tutti gli articoli
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Articolo

export const pageQuery = graphql`
  query ArticoloBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulArticolo(slug: { eq: $slug }) {
      id
      slug
      titolo
      contenuto {
        childMarkdownRemark {
          html
        }
        contenuto
      }
      categorie {
        id
        label
        slug
      }
      autore {
        cognome
        nome
        descrizione
      }
      dataPubblicazione(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1440, maxHeight: 1440, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
        file {
          url
        }
      }
    }
  }
`
